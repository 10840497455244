import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSurvey, updateSurvey } from "../services/api";
import QuestionForm from "../components/QuestionForm";
import "../styles/EditSurvey.css";

const EditSurvey = () => {
  const { id } = useParams(); // Survey ID from URL
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the existing survey data
    const getSurvey = async () => {
      try {
        const { data } = await fetchSurvey(id);
        setTitle(data.title);
        setDescription(data.description);
        setQuestions(data.questions);
      } catch (err) {
        console.error("Error fetching survey:", err);
      } finally {
        setLoading(false);
      }
    };

    getSurvey();
  }, [id]);

  const addQuestion = () => {
    setQuestions([...questions, { id: Date.now(), text: "", type: "text", options: [] }]);
  };

  const updateQuestion = (updatedQuestion) => {
    setQuestions(questions.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q)));
  };

  const removeQuestion = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const handleSubmit = async () => {
    try {
      const updatedData = {
        title,
        description,
        questions: questions.map(({ id, ...rest }) => rest), // Remove temporary IDs
      };
      await updateSurvey(id, updatedData);
      alert("Survey updated successfully!");
      navigate("/admin"); // Redirect back to admin page
    } catch (err) {
      console.error("Error updating survey:", err);
      alert("Failed to update survey.");
    }
  };

  if (loading) {
    return <p>Loading survey...</p>;
  }

  return (
    <div className="edit-survey">
      <h2>Edit Survey</h2>
      <div className="survey-card">
        <input
          type="text"
          placeholder="Survey Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="text-input"
        />
        <textarea
          placeholder="Survey Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="text-area"
        ></textarea>
      </div>
      <div className="question-list">
        {questions.map((q) => (
          <QuestionForm
            key={q.id || q._id}
            question={q}
            onUpdate={updateQuestion}
            onRemove={removeQuestion}
          />
        ))}
      </div>
      <button className="add-question-button" onClick={addQuestion}>
        Add Question
      </button>
      <button className="save-survey-button" onClick={handleSubmit}>
        Save Changes
      </button>
    </div>
  );
};

export default EditSurvey;
