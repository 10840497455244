import React from "react";
import "../styles/Portfolio.css";

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <h1 className="portfolio-title">Our Projects</h1>

      {/* AI Exercise Recommender */}
      <section className="portfolio-section">
        <div className="portfolio-text">
          <h2>AI Exercise Recommender</h2>
          <p>
            A smart AI-powered system that recommends exercise programs based on user input such as age, weight, and fitness goals. 
            Designed for personal trainers and fitness enthusiasts.
          </p>
        </div>
        <div className="portfolio-video">
          <video autoPlay muted loop className="project-video">
            <source src="/videos/ai-exercise-recommender.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      <hr className="separator" />

      {/* Heavy Vehicle Navigation System */}
      <section className="portfolio-section">
        <div className="portfolio-text">
          <h2>Heavy Vehicle Navigation System</h2>
          <p>
            A specialized navigation platform for large vehicles, optimizing routes for efficiency and safety. 
            Integrated with real-time traffic updates and AI-driven routing.
          </p>
        </div>
        <div className="portfolio-video">
          <video autoPlay muted loop className="project-video">
            <source src="/videos/heavy-vehicle-navigation.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
      <hr className="separator" />

      {/* Survey Platform */}
      <section className="portfolio-section">
        <div className="portfolio-text">
          <h2>Survey Platform</h2>
          <p>
            An interactive survey management system designed to collect, analyze, and visualize user feedback efficiently. 
            Built with AI-powered insights for businesses and researchers.
          </p>
        </div>
        <div className="portfolio-video">
          <video autoPlay muted loop className="project-video">
            <source src="/videos/survey-platform.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
