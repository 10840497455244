import React from "react";
import SubmitSurveyForm from "../components/SubmitSurveyForm";

const SubmitSurvey = () => {
  return (
    <div>
      <SubmitSurveyForm />
    </div>
  );
};

export default SubmitSurvey;
