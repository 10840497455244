import axios from "axios";

// Use the environment variable or fallback to localhost
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:5000/api",
});

// Add token to protected requests
API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Public actions
export const fetchPublicSurveys = () => API.get("/surveys");
export const fetchSurvey = (id) => API.get(`/surveys/${id}`); // Fetch a single survey by ID
export const submitResponse = (data) => API.post("/responses", data);

// Admin actions
export const fetchSurveys = () => API.get("/admin/surveys");
export const createSurvey = (data) => API.post("/admin/surveys", data);
export const updateSurvey = (id, data) => API.put(`/admin/surveys/${id}`, data);
export const deleteSurvey = (id) => API.delete(`/admin/surveys/${id}`);
export const fetchResponses = (surveyId) => API.get(`/admin/responses/${surveyId}`);
export const deleteResponse = (id) => API.delete(`/admin/responses/${id}`);

export default API;
