import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import "../styles/ResponsesTable.css";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ResponsesTable = ({ responses, surveyQuestions }) => {
  const [chartsData, setChartsData] = useState({});
  const [geoDataSummary, setGeoDataSummary] = useState({});

  // Prepare charts and geolocation summaries
  useEffect(() => {
    const dataByQuestion = {};
    const geoSummary = {};

    // Initialize chart data structure
    surveyQuestions.forEach((question) => {
      dataByQuestion[question._id] = {};
    });

    // Populate chart and geolocation data
    responses.forEach((response) => {
      // Geolocation summary
      const location = response.geoLocation || "Unknown";
      if (!geoSummary[location]) {
        geoSummary[location] = 0;
      }
      geoSummary[location] += 1;

      // Answer distribution
      response.answers.forEach((answer) => {
        const questionId = answer.questionId;
        const responseValue = Array.isArray(answer.answer)
          ? answer.answer
          : [answer.answer];

        responseValue.forEach((value) => {
          if (!dataByQuestion[questionId][value]) {
            dataByQuestion[questionId][value] = 0;
          }
          dataByQuestion[questionId][value] += 1;
        });
      });
    });

    setChartsData(dataByQuestion);
    setGeoDataSummary(geoSummary);
  }, [responses, surveyQuestions]);

  // Render charts for each question
  const renderCharts = () =>
    surveyQuestions.map((question) => {
      const data = chartsData[question._id] || {};
      const labels = Object.keys(data);
      const values = Object.values(data);

      return (
        <div key={question._id} className="chart-container">
          <h3>{question.text}</h3>
          {labels.length > 0 ? (
            <Pie
              data={{
                labels,
                datasets: [
                  {
                    data: values,
                    backgroundColor: [
                      "#4caf50",
                      "#ff5722",
                      "#2196f3",
                      "#ffeb3b",
                      "#9c27b0",
                      "#03a9f4",
                    ],
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
              }}
            />
          ) : (
            <p>No data available for this question.</p>
          )}
        </div>
      );
    });

  // Render geolocation summary
  const renderGeoSummary = () => {
    const labels = Object.keys(geoDataSummary);
    const values = Object.values(geoDataSummary);

    return (
      <div className="chart-container">
        <h3>Responses by Location</h3>
        {labels.length > 0 ? (
          <Pie
            data={{
              labels,
              datasets: [
                {
                  data: values,
                  backgroundColor: [
                    "#4caf50",
                    "#ff5722",
                    "#2196f3",
                    "#ffeb3b",
                    "#9c27b0",
                    "#03a9f4",
                  ],
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
            }}
          />
        ) : (
          <p>No location data available.</p>
        )}
      </div>
    );
  };

  return (
    <div className="responses-container">
      <h2>Survey Responses</h2>
      <table className="responses-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Other Input</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {responses.map((response, index) =>
            response.answers.map((answer, i) => {
              const question = surveyQuestions.find(
                (q) => q._id === answer.questionId
              );
              return (
                <tr key={`${response._id}-${i}`}>
                  <td>{index + 1}</td>
                  <td>{question?.text || "Unknown Question"}</td>
                  <td>
                    {Array.isArray(answer.answer)
                      ? answer.answer.join(", ")
                      : answer.answer}
                  </td>
                  <td>{answer.other || "N/A"}</td> {/* Display the "Other" input if available */}
                  <td>{response.geoLocation || "Unknown"}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <div className="charts">
        {renderCharts()}
        {renderGeoSummary()}
      </div>
    </div>
  );
};

export default ResponsesTable;
