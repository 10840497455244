import React, { useState, useEffect } from "react";
import {
  fetchSurveys,
  deleteSurvey,
  fetchResponses,
  deleteResponse,
} from "../services/api";
import { useNavigate } from "react-router-dom";
import "../styles/SurveyManagement.css";

const SurveyManagement = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [responses, setResponses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadSurveys = async () => {
      try {
        const { data } = await fetchSurveys();
        setSurveys(data);
      } catch (err) {
        console.error("Error fetching surveys:", err);
      }
    };

    loadSurveys();
  }, []);

  const handleEditSurvey = (id) => {
    navigate(`/edit-survey/${id}`); // Redirect to EditSurvey page
  };

  const handleDeleteSurvey = async (id) => {
    try {
      await deleteSurvey(id);
      setSurveys((prev) => prev.filter((s) => s._id !== id));
      alert("Survey deleted successfully!");
    } catch (err) {
      console.error("Error deleting survey:", err);
      alert("Failed to delete survey.");
    }
  };

  const loadResponses = async (surveyId) => {
    try {
      const { data } = await fetchResponses(surveyId);
      setResponses(data);
      setSelectedSurvey(surveyId);
    } catch (err) {
      console.error("Error fetching responses:", err);
    }
  };

  const handleDeleteResponse = async (id) => {
    try {
      await deleteResponse(id);
      setResponses((prev) => prev.filter((r) => r._id !== id));
      alert("Response deleted successfully!");
    } catch (err) {
      console.error("Error deleting response:", err);
      alert("Failed to delete response.");
    }
  };

  return (
    <div className="survey-management">
      <h2>Survey Management</h2>
      {!selectedSurvey ? (
        <div className="survey-list">
          {surveys.map((survey) => (
            <div key={survey._id} className="survey-card">
              <h3>{survey.title}</h3>
              <p>{survey.description}</p>
              <button onClick={() => loadResponses(survey._id)}>View Responses</button>
              <button onClick={() => handleEditSurvey(survey._id)}>Edit</button>
              <button onClick={() => handleDeleteSurvey(survey._id)}>Delete</button>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <button className="back-button" onClick={() => setSelectedSurvey(null)}>
            Back to Surveys
          </button>
          <h3>Responses</h3>
          <ul className="response-list">
            {responses.map((response) => (
              <li key={response._id} className="response-item">
                {JSON.stringify(response.answers)}
                <button onClick={() => handleDeleteResponse(response._id)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SurveyManagement;
