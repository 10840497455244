import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import CreateSurvey from "./pages/CreateSurvey";
import SubmitSurvey from "./pages/SubmitSurvey";
import ViewResponses from "./pages/ViewResponses";
import SurveyList from "./components/SurveyList";
import AdminPage from "./pages/AdminPage";
import EditSurvey from "./pages/EditSurvey";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import "./index.css";

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content"> {/* This applies the fix globally */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/surveys" element={<SurveyList />} />
          <Route path="/submit/:id" element={<SubmitSurvey />} />
          <Route path="/login" element={<Login />} />

          {/* Private Routes */}
          <Route
            path="/create-survey"
            element={
              <PrivateRoute>
                <CreateSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/responses"
            element={
              <PrivateRoute>
                <ViewResponses />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-survey/:id"
            element={
              <PrivateRoute>
                <EditSurvey />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
