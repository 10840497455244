import React from "react";
import SurveyBuilder from "../components/SurveyBuilder";

const CreateSurvey = () => {
  return (
    <div>
      <SurveyBuilder />
    </div>
  );
};

export default CreateSurvey;
