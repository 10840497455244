import React from "react";
import SurveyManagement from "../components/SurveyManagement";


const AdminPage = () => {
  return (
    <div className="admin-page">
      <h1>Admin Dashboard</h1>
      <SurveyManagement />
    </div>
  );
};

export default AdminPage;
