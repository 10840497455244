import React, { useState } from "react";
import "../styles/QuestionForm.css";

const QuestionForm = ({ question, onUpdate, onRemove }) => {
  const [options, setOptions] = useState(question.options || []);

  const handleTextChange = (e) => {
    onUpdate({ ...question, text: e.target.value });
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    const updatedQuestion = { ...question, type: newType };

    if (newType === "rating") {
      updatedQuestion.options = ["1", "2", "3", "4", "5"]; // Default rating scale
    } else if (newType !== "multiple-choice" && newType !== "single-answer") {
      updatedQuestion.options = [];
      updatedQuestion.allowOther = false;
    }

    onUpdate(updatedQuestion);
  };

  const addOption = () => {
    setOptions([...options, ""]);
    onUpdate({ ...question, options: [...options, ""] });
  };

  const updateOption = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
    onUpdate({ ...question, options: updatedOptions });
  };

  const removeOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    onUpdate({ ...question, options: updatedOptions });
  };

  const toggleAllowOther = () => {
    const updatedQuestion = { ...question, allowOther: !question.allowOther };
    onUpdate(updatedQuestion);
  };

  const toggleRequired = () => {
    const updatedQuestion = { ...question, required: !question.required };
    onUpdate(updatedQuestion);
  };

  return (
    <div className="question-form">
      <div className="question-header">
        <input
          type="text"
          value={question.text}
          onChange={handleTextChange}
          placeholder="Enter your question"
          className="question-input"
        />
        <select
          value={question.type}
          onChange={handleTypeChange}
          className="question-type-select"
        >
          <option value="text">Text</option>
          <option value="multiple-choice">Multiple Choice</option>
          <option value="single-answer">Single Answer</option>
          <option value="rating">Rating</option>
        </select>
      </div>

      {(question.type === "multiple-choice" || question.type === "single-answer") && (
        <div className="options-container">
          <h4 className="options-title">Options</h4>
          {options.map((option, index) => (
            <div key={index} className="option-item">
              <input
                type="text"
                value={option}
                onChange={(e) => updateOption(index, e.target.value)}
                placeholder={`Option ${index + 1}`}
                className="option-input"
              />
              <button
                type="button"
                onClick={() => removeOption(index)}
                className="remove-option-button"
              >
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={addOption} className="add-option-button">
            Add Option
          </button>
          <label className="allow-other-label">
            <input
              type="checkbox"
              checked={question.allowOther || false}
              onChange={toggleAllowOther}
            />
            Allow "Other" option
          </label>
        </div>
      )}

      {question.type === "rating" && (
        <div className="rating-container">
          <h4>Rating Scale (1-5)</h4>
          <p>Users will select a rating from 1 to 5.</p>
        </div>
      )}

      <div className="required-toggle">
        <label>
          <input
            type="checkbox"
            checked={question.required || false}
            onChange={toggleRequired}
          />
          Required
        </label>
      </div>

      <button
        type="button"
        onClick={() => onRemove(question.id)}
        className="remove-question-button"
      >
        Remove Question
      </button>
    </div>
  );
};

export default QuestionForm;
