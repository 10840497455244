import React from "react";
import "../styles/About.css";
import { FaBrain, FaCogs, FaLaptopCode, FaRocket, FaUsers } from "react-icons/fa";

const teamMembers = [
  {
    name: "Christos",
    role: "Founder & Lead Developer",
    bio: "Chris is a passionate software engineer with expertise in software and web development.",
    image: "/images/christos.jpg",
  },
  {
    name: "Iuliana",
    role: "Project Manager",
    bio: "Iuliana ensures smooth project execution, bringing innovative solutions to the forefront.",
    image: "/images/iuliana.jpg",
  },
  {
    name: "Ana-Maria",
    role: "Lead Designer",
    bio: "Ana specializes in smooth designs and friendly UI for the best user experience.",
    image: "/images/anna-maria.jpg",
  },
];

const steps = [
  { icon: <FaBrain />, title: "Research & Development", description: "Understanding challenges and researching innovative AI solutions." },
  { icon: <FaCogs />, title: "AI Model Training", description: "Developing and training AI models tailored for business needs." },
  { icon: <FaLaptopCode />, title: "Software Development", description: "Integrating AI into software applications for automation." },
  { icon: <FaRocket />, title: "Deployment & Optimization", description: "Ensuring seamless implementation and continuous improvements." },
  { icon: <FaUsers />, title: "Ongoing Support", description: "Providing continuous support and updates for scalability." },
];

const About = () => {
  return (
    <div className="about-container">
      {/* Hero Video Section */}
      <section className="hero-video-section">
        <video autoPlay muted loop className="hero-video">
          <source src="/videos/about-page.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      {/* Company Description Section (Grey Background) */}
      <section className="company-description">
        <h1>About Us</h1>
        <p>
          At CAJ Digital Technologies, we specialize in developing AI-powered software that helps startups and medium-sized businesses in the UK
          automate workflows, optimize business operations, and enhance productivity. Our solutions are designed to streamline processes, improve efficiency, and unlock growth opportunities.
        </p>
      </section>

      {/* Step-by-step Process */}
      <section className="workflow-section">
        {steps.map((step, index) => (
          <div key={index} className={`workflow-step ${index % 2 === 0 ? "grey-bg" : ""}`}>
            <div className="icon-container">{step.icon}</div>
            <div className="text-container">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </section>

      {/* Meet the Team */}
      <section className="team-section">
        <h2>Meet the Team</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div className="team-card" key={index}>
              <img src={member.image} alt={member.name} className="team-image" />
              <h3>{member.name}</h3>
              <h4>{member.role}</h4>
              <p>{member.bio}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
