import React from "react";
import "../styles/ResponseSurveyList.css";

const ResponseSurveyList = ({ surveys, onSelectSurvey }) => {
  return (
    <div className="response-survey-list">
      {surveys.map((survey) => (
        <div
          className="response-survey-card"
          key={survey._id}
          onClick={() => onSelectSurvey(survey)}
        >
          <h3>{survey.title}</h3>
          <p>{survey.description || "No description available"}</p>
        </div>
      ))}
    </div>
  );
};

export default ResponseSurveyList;
