import React, { useState } from "react";
import "../styles/Contact.css";

const Contact = () => {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const API_BASE_URL = process.env.REACT_APP_API_URL || "https://your-heroku-app.herokuapp.com";

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/contact/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        setStatus("Message sent successfully!");
        setForm({ name: "", email: "", message: "" });
      } else {
        setStatus("Failed to send message. Please try again.");
      }
    } catch (error) {
      setStatus("Error sending message. Please try again later.");
    }
  };

  return (
    <div className="contact-container">
      {/* Hero Video Section */}
      <section className="hero-video-section">
        <video autoPlay muted loop className="hero-video">
          <source src="/videos/contact-hero.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      {/* Contact Information */}
      <section className="contact-info">
        <h1>Contact Us</h1>
        <p>
          Whether you’re looking for a <b>project quote</b>, <b>collaboration</b>, or have 
          any <b>general inquiries</b>, we’re here to help! Send us a message, and 
          we’ll get back to you as soon as possible.
        </p>
      </section>

      {/* Contact Form */}
      <form className="contact-form" onSubmit={handleSubmit}>
        <input 
          type="text" 
          name="name" 
          placeholder="Your Name" 
          value={form.name} 
          onChange={handleChange} 
          required 
        />
        <input 
          type="email" 
          name="email" 
          placeholder="Your Email" 
          value={form.email} 
          onChange={handleChange} 
          required 
        />
        <textarea 
          name="message" 
          placeholder="Your Message" 
          value={form.message} 
          onChange={handleChange} 
          required 
        />
        <button type="submit">Send Message</button>
      </form>

      {/* Status Message */}
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default Contact;

