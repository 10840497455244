import React, { useState, useEffect } from "react";
import { fetchSurveys, fetchResponses } from "../services/api";
import ResponseSurveyList from "../components/ResponseSurveyList";
import ResponsesTable from "../components/ResponsesTable";

const ViewResponses = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all surveys
  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await fetchSurveys();
        setSurveys(data);
      } catch (err) {
        console.error("Error fetching surveys:", err);
      } finally {
        setLoading(false);
      }
    };

    getSurveys();
  }, []);

  // Fetch responses for the selected survey
  const handleSelectSurvey = async (survey) => {
    setSelectedSurvey(survey);
    try {
      const { data } = await fetchResponses(survey._id);
      setResponses(data);
    } catch (err) {
      console.error("Error fetching responses:", err);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Survey Responses</h2>
      {!selectedSurvey ? (
        <ResponseSurveyList surveys={surveys} onSelectSurvey={handleSelectSurvey} />
      ) : (
        <div>
          <button onClick={() => setSelectedSurvey(null)}>Back to Surveys</button>
          <h3>{selectedSurvey.title}</h3>
          <ResponsesTable responses={responses} surveyQuestions={selectedSurvey.questions} />
        </div>
      )}
    </div>
  );
};

export default ViewResponses;
