import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPublicSurveys } from "../services/api";
import "../styles/SurveySection.css";

const SurveySection = () => {
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await fetchPublicSurveys();
        setSurveys(data.slice(0, 3)); // Show latest 3 surveys
      } catch (err) {
        console.error("Error fetching surveys:", err);
      }
    };
    getSurveys();
  }, []);

  return (
    <section className="survey-preview">
      <h2>Recent Surveys</h2>
      <p>Participate in our latest research and help shape the future.</p>
      <div className="survey-cards">
        {surveys.length === 0 ? (
          <p>No surveys available.</p>
        ) : (
          surveys.map((survey) => (
            <div key={survey._id} className="survey-card">
              <h3>{survey.title}</h3>
              <p>{survey.description || "No description available"}</p>
              <Link to={`/submit/${survey._id}`} className="btn">Take Survey</Link>
            </div>
          ))
        )}
      </div>
      <div className="btn-container">
        <Link to="/surveys" className="btn">View All Surveys</Link>
      </div>
    </section>
  );
};

export default SurveySection;
