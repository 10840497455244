import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Header.css";

const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    alert("Logged out successfully!");
    navigate("/login");
    setMenuOpen(false); // Close menu on logout
  };

  const isAdminAuthenticated = !!localStorage.getItem("token");

  // Function to close menu when clicking a link
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        {/* Logo on the left */}
        <div className="navbar-left">
          <Link to="/" onClick={closeMenu}>
            <img src="/logo.png" alt="Survey platform logo" className="header-logo" />
          </Link>
        </div>

        {/* Navigation links */}
        <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/about" onClick={closeMenu}>About</Link></li>
            <li><Link to="/portfolio" onClick={closeMenu}>Portfolio</Link></li>
            <li><Link to="/surveys" onClick={closeMenu}>Surveys</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>

            {isAdminAuthenticated ? (
              <>
                <li><Link to="/create-survey" onClick={closeMenu}>Create Survey</Link></li>
                <li><Link to="/responses" onClick={closeMenu}>View Responses</Link></li>
                <li><Link to="/admin" onClick={closeMenu}>Admin Dashboard</Link></li>
                <li>
                  <button onClick={handleLogout} className="logout-button">Logout</button>
                </li>
              </>
            ) : (
              <li><Link to="/login" onClick={closeMenu}>Admin Login</Link></li>
            )}
          </ul>
        </nav>

        {/* Hamburger menu for mobile */}
        <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </div>
      </div>

      {/* Overlay when menu is open */}
      {menuOpen && <div className="menu-overlay" onClick={closeMenu}></div>}
    </header>
  );
};

export default Header;
