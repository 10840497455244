import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import PortfolioSection from "../components/PortfolioSection";
import SurveySection from "../components/SurveySection";

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section with Video */}
      <section className="hero-section">
        <video autoPlay muted loop className="hero-video">
          <source src="/videos/hero-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-overlay">
          <h1>Innovating with AI & Automation</h1>
          <p>We develop AI-powered solutions that streamline business operations and drive efficiency.</p>
          <Link to="/contact" className="btn">Get in Touch</Link>
        </div>
      </section>

      {/* About Section with Video Preview */}
      <section className="about-section">
        <div className="about-text">
          <h2>Empowering Businesses with AI</h2>
          <p>We develop intelligent automation software that helps startups and medium-sized businesses streamline their operations.</p>
          <div className="btn-container">
            <Link to="/about" className="btn">Learn More</Link>
          </div>
        </div>
        <div className="about-video">
          <video autoPlay muted loop className="about-preview-video">
            <source src="/videos/about-section.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      {/* Portfolio Section */}
      <PortfolioSection />

      {/* Survey Section */}
      <SurveySection />

    </div>
  );
};

export default Home;
