import React, { useState } from "react";
import QuestionForm from "./QuestionForm";
import { createSurvey } from "../services/api";
import "../styles/SurveyBuilder.css";

const SurveyBuilder = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);

  const addQuestion = () => {
    setQuestions([...questions, { id: Date.now(), text: "", type: "text", options: [] }]);
  };

  const updateQuestion = (updatedQuestion) => {
    setQuestions(questions.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q)));
  };

  const removeQuestion = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const saveSurvey = async () => {
    try {
      const formattedQuestions = questions.map(({ id, ...rest }) => rest);
      const surveyData = { title, description, questions: formattedQuestions };
      await createSurvey(surveyData);
      alert("Survey created successfully!");
      setTitle("");
      setDescription("");
      setQuestions([]);
    } catch (err) {
      console.error("Error creating survey:", err);
      alert("Failed to create survey.");
    }
  };

  return (
    <div className="survey-builder">
      <h2 className="survey-title">Create a New Survey</h2>
      <div className="survey-card">
        <input
          type="text"
          placeholder="Survey Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="text-input"
        />
        <textarea
          placeholder="Survey Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="text-area"
        ></textarea>
      </div>
      <div className="question-list">
        {questions.map((q) => (
          <QuestionForm
            key={q.id}
            question={q}
            onUpdate={updateQuestion}
            onRemove={removeQuestion}
          />
        ))}
      </div>
      <div className="survey-actions">
        <button className="add-question-button" onClick={addQuestion}>
          Add Question
        </button>
        <button
          className="save-survey-button"
          onClick={saveSurvey}
          disabled={!title || !description || questions.length === 0}
        >
          Save Survey
        </button>
      </div>
    </div>
  );
};

export default SurveyBuilder;
