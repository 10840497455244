import React from "react";
import { Link } from "react-router-dom";
import "../styles/PortfolioSection.css";

// Portfolio data
const projects = [
  {
    title: "AI Exercise Recommender",
    image: "/images/ai-exercise-recommender.jpg",
    description: "AI-powered fitness recommendations based on personal data.",
    link: "/portfolio",
    ribbon: null,
  },
  {
    title: "Heavy Vehicle Navigation",
    image: "/images/navigation-system.jpg",
    description: "Optimized routes for large vehicles using AI-driven maps.",
    link: "/portfolio",
    ribbon: "In Development",
  },
  {
    title: "Survey Platform",
    image: "/images/survey-platform.jpg",
    description: "An intelligent survey system with data-driven insights.",
    link: "/portfolio",
    ribbon: null,
  },
];

const PortfolioSection = () => {
  return (
    <section className="portfolio-preview">
      <h2>Our Featured Projects</h2>
      <p>Discover our AI-driven projects transforming industries.</p>
      <div className="portfolio-cards">
        {projects.map((project, index) => (
          <div key={index} className="portfolio-card">
            {project.ribbon && <div className="ribbon">{project.ribbon}</div>}
            <img src={project.image} alt={project.title} className="portfolio-image" />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Link to={project.link} className="btn">Learn More</Link>
          </div>
        ))}
      </div>
      <div className="btn-container">
        <Link to="/portfolio" className="btn">View Full Portfolio</Link>
      </div>
    </section>
  );
};

export default PortfolioSection;
