import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPublicSurveys } from "../services/api"; // Use public API call
import "../styles/SurveyList.css";

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await fetchPublicSurveys(); // Use public fetch route
        setSurveys(data);
      } catch (err) {
        console.error("Error fetching surveys:", err);
      }
    };
    getSurveys();
  }, []);

  return (
    <div className="survey-list-container">
      {/* Hero Video Section */}
      <section className="hero-video-section">
        <video autoPlay muted loop className="hero-video">
          <source src="/videos/surveys-hero-section.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      {/* Surveys Section */}
      <section className="surveys-section">
        <h1 className="survey-list-title">Available Surveys</h1>
        {surveys.length === 0 ? (
          <p>No surveys available.</p>
        ) : (
          <div className="survey-cards">
            {surveys.map((survey) => (
              <div className="survey-card" key={survey._id}>
                <h3>{survey.title}</h3>
                <p>{survey.description || "No description available"}</p>
                <Link to={`/submit/${survey._id}`} className="btn">Take Survey</Link>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default SurveyList;
