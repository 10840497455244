import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchSurvey, submitResponse } from "../services/api";
import "../styles/SubmitSurveyForm.css";

const SubmitSurveyForm = () => {
  const { id } = useParams();
  const [survey, setSurvey] = useState(null);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    const getSurvey = async () => {
      try {
        const { data } = await fetchSurvey(id);
        setSurvey(data);
      } catch (err) {
        console.error("Error fetching survey:", err);
      } finally {
        setLoading(false);
      }
    };

    getSurvey();
  }, [id]);

  const handleOptionChange = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: {
        answer: value,
        ...(value === "Other" ? { other: "" } : {}),
      },
    }));
    setErrorMessages((prev) => ({
      ...prev,
      [questionId]: undefined,
    }));
  };

  const handleOtherInputChange = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        other: value,
      },
    }));
  };

  const handleTextChange = (questionId, value) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: { answer: value },
    }));
    setErrorMessages((prev) => ({
      ...prev,
      [questionId]: undefined,
    }));
  };

  const validateResponses = () => {
    const errors = {};
    survey.questions.forEach((question) => {
      const response = responses[question._id];

      if (question.required) {
        if (!response || !response.answer) {
          errors[question._id] = "This question is required.";
        } else if (
          question.type === "text" &&
          (!response.answer || response.answer.trim() === "")
        ) {
          errors[question._id] = "Please enter a response.";
        } else if (
          question.type === "single-answer" &&
          response.answer === "Other" &&
          (!response.other || response.other.trim() === "")
        ) {
          errors[question._id] = 'Please specify for "Other".';
        }
      }
    });

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateResponses()) return;

    try {
      const responseData = {
        surveyId: id,
        answers: Object.keys(responses).map((questionId) => ({
          questionId,
          answer: responses[questionId]?.answer,
          other: responses[questionId]?.other || null,
        })),
      };

      await submitResponse(responseData);
      setCompleted(true);
    } catch (err) {
      console.error("Error submitting response:", err);
      
      // Check if user has already submitted
      if (err.response?.status === 403) {
        setAlreadySubmitted(true);
      } else {
        alert("An error occurred while submitting the survey.");
      }
    }
  };

  if (loading) return <p>Loading survey...</p>;
  if (completed) return <h2>Thank you for completing the survey!</h2>;
  if (alreadySubmitted) {
    return (
      <div className="already-submitted-message">
        <h2>Survey Already Submitted</h2>
        <p>You have already submitted this survey. Thank you for your participation!</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="survey-form">
      <h2 className="survey-title">{survey?.title}</h2>
      <p className="survey-description">{survey?.description}</p>

      {survey?.questions.map((question, index) => (
        <div
          key={question._id}
          className={`question-container ${errorMessages[question._id] ? "error" : ""}`}
        >
          <p className="question-text">
            {question.text}
            {question.required && <span className="required-asterisk">*</span>}
          </p>

          {question.type === "text" && (
            <input
              type="text"
              className="text-input"
              placeholder="Type your answer here"
              value={responses[question._id]?.answer || ""}
              onChange={(e) => handleTextChange(question._id, e.target.value)}
            />
          )}

          {(question.type === "multiple-choice" || question.type === "single-answer") &&
            question.options.map((option, i) => (
              <label key={i} className="option-label">
                <input
                  type={question.type === "multiple-choice" ? "checkbox" : "radio"}
                  name={question._id}
                  value={option}
                  onChange={(e) => handleOptionChange(question._id, e.target.value)}
                />
                {option}
              </label>
            ))}

          {question.type === "rating" && (
            <div className="rating-container">
              {[1, 2, 3, 4, 5].map((num) => (
                <label key={num} className="option-label">
                  <input
                    type="radio"
                    name={question._id}
                    value={num}
                    onChange={(e) => handleOptionChange(question._id, e.target.value)}
                  />
                  {num}
                </label>
              ))}
            </div>
          )}

          {question.allowOther && (
            <div className="other-option">
              <label>
                <input
                  type="radio"
                  name={question._id}
                  value="Other"
                  onChange={() => handleOptionChange(question._id, "Other")}
                />
                Other
              </label>
              {responses[question._id]?.answer === "Other" && (
                <input
                  type="text"
                  placeholder="Please specify"
                  className="other-textbox"
                  onChange={(e) =>
                    handleOtherInputChange(question._id, e.target.value)
                  }
                  value={responses[question._id]?.other || ""}
                />
              )}
            </div>
          )}

          {errorMessages[question._id] && (
            <p className="error-message">{errorMessages[question._id]}</p>
          )}

          {index < survey.questions.length - 1 && <hr className="question-divider" />}
        </div>
      ))}
      <button type="submit" className="submit-button">Submit</button>
    </form>
  );
};

export default SubmitSurveyForm;
